<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container :paddedTopBottom="false">
          <h2><strong>1</strong> Vraaggesprek</h2>
          <p>
            Tijdens het eerste consult wordt er begonnen met een uit­gebreid
            vraag­gesprek. Naast de vragen welke direct betrek­king hebben op de
            klacht, besteden we ook aan­dacht aan de ziekte­geschiedenis,
            leef­gewoonten en moge­lijke famili­aire klachten. Een uit­gebreid
            vraag­gesprek stelt ons ook in staat om poten­tieel ernstige ziektes
            uit te sluiten.
          </p>
          <h2><strong>2</strong> Onderzoek</h2>
          <p>
            Het onder­zoek wordt gespreid in drie delen. Er wordt gestart met
            een inspectie, waarbij we samen kijken naar uw houding,
            stands­verandering van gewricht­en en andere opvallend­heden.
            Vervolgens worden er veiligheids­testen uitgevoerd, om te bepalen of
            een osteo­pathische behandeling geschikt is. Hierna volgt er een
            bewegings­onderzoek waarbij de beweeglijk­heid van het gehele
            lichaam in kaart wordt gebracht. De uitkomsten van het onderzoek
            worden met u besproken en er zal gezamen­lijk een behandel­plan
            worden opgesteld.
          </p>
          <h2><strong>3</strong> Behandeling</h2>
          <p>
            Een behande­ling duurt tussen de 30 en 45 minuten. Tijdens de
            behande­ling kunnen de hande­lingen variëren van subtiel tot stevig.
            Tevens kunnen deze hande­lingen plaats­vinden op andere plaatsen dan
            waar de klacht zich bevindt. Er is echter altijd een relatie met de
            oor­spronkelijke klacht. Osteo­paten gebruiken geen appa­ratuur of
            medicijnen. Het is mogelijk dat in de eerste dagen na een
            behandeling enige na­pijn optreedt, ook op plaatsen waar eerder geen
            klachten waren. Het lichaam heeft tijd nodig om zich aan te passen
            aan de correc­ties. Het aantal behande­lingen verschilt per patiënt
            en is afhankelijk van de aard van de klacht. In het algemeen zal er
            binnen 3 à 4 behandelingen een duidelijk effect waar­neem­baar zijn.
            Is dit niet het geval, dan zullen wij u adviseren om te stop­pen met
            de behandeling. Indien nodig wordt er samen­gewerkt met andere zorg-
            of hulpverleners.
          </p>
          <h2><strong>4</strong> Advies</h2>
          <p>
            Wij advi­seren om 2 tot 4 maal per jaar langs te komen voor een
            controle af­spraak. Op deze manier kunnen wij preven­tief werken om
            uw gezond­heid te waar­borgen. Daar­naast kunnen wij u ad­viezen
            mee­geven op het gebied van bewe­ging, voeding en ontspan­ning.
            In­dien uit het onder­zoek blijkt dat er sprake kan zijn van een
            onder­liggende ziekte, waarvoor osteo­pathie geen behan­deling kan
            bieden, zullen wij u (terug)verwijzen naar de huis­arts of
            specialist.
          </p>
        </Container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Container from "../components/Container.vue";

export default {
  name: "Werkwijze",
  components: { Container }
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 2rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 1rem;
  display: inline-block;

  em {
    font-style: normal;
    color: #7f8274;
  }

  strong {
    background-color: #cacfb8;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
    font-weight: 200;
    transform: translate(0, -0.125rem);
  }
}
</style>
